import React from "react";

const EventDetails = (props: any) => {

  const getProductsContent = (products: any) => {
    let content = [];
    if (products.length) {
      for (let item of products) {
        content.push(<div className="col-lg-6 col-md-6" key={item.id}>
          <div className="products">
            <div className="productImg">
              <a className="html5lightbox" target="_blank" href={item.url}>
                <span> <img src={item.image} alt={"img"}/></span>
              </a>
            </div>
            <div className="productContent">
              <h4><a href={item.url} target="_blank">{item.title}</a></h4>
              <p>${item.price}</p>
            </div>
          </div>
        </div>);
      }
    } else {
      content.push(
        <div className="col-lg-6 col-md-6" key={'noProductId'}>
          No Product Found!
        </div>
      )
    }
    return content;
  };
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="productsList">
          <div className="streamDesc">
            <h3>{props.eventDetails.title}</h3>
            <p>{props.eventDetails.event_desc}</p>
          </div>

          <div className="productListing">
            <div className="row">
              {getProductsContent(props.eventDetails.products)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EventDetails;
